/* Message.css */
.message {
    margin: 10px;
    position: relative;
    display: flex;
  }

  .mymessage-style {
    justify-content: right;
  }

  .message-content {
    border: 2px solid #ddd;
    border-radius: 16px;
    width: 90%;
    padding: 8px;
    margin: 0;
    background-color: #FFE4E9;
  }

  .mymessage-content-style {
    background-color: #C9F2FF;
  }
  
  .message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .message.info {
    background-color: #d1ecf1;
    color: #0c5460;
    border: 1px solid #bee5eb;
  }
  
  .message button {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .messages-container {
    padding-top: 50px;
    bottom: 50px;
    position: relative;
    max-height: 80vh;
    overflow: scroll;
  }

  .messages-text-area {
    position: fixed;
    bottom: 0;
    padding: 8px;
  }
  